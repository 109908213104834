@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .header-action-button {
    @apply inline-flex items-center rounded-md bg-cyan-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600;
  }
}

#headlessui-portal-root .fixed.inset-0.z-40.flex {
  height: calc(100vh - 64px);
  top: 64px;
}

/* -------- app-home ここから -------- */
.reservation,
.goal,
.home-slider,
.home-info {
  margin-top: 20px;
}

.slide-wrapper,
.home-info {
  background-color: #fff;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

.arrow-icon {
  padding-right: 28px;
  padding-right: 25px;
  position: relative;
}

.arrow-icon::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: rotate(45deg) translateY(-50%);
}

.confirmation.arrow-icon::after {
  border-color: #ff8a00;
}

.goal.arrow-icon::after {
  border-color: #fff;
}

.app-home {
  background-color: #f3f3f3;
  min-height: 100vh;
  padding: 0 20px 20px;
}

/* -- header -- */
.home-header {
  background-color: #001e2a;
  margin: 0 -20px;
  padding: 20px 20px 25px;
  border-radius: 0 0 10px 10px;
}

.app-situation {
  display: flex;
}

.app-situation li {
  font-size: 15px;
  color: #fff;
  text-align: center;
  width: 33.333%;
}

.app-situation li:not(:last-child) {
  border-right: solid 1px #e6e6e6;
}

.app-situation .subject {
  font-size: 12px;
  display: block;
  margin-bottom: 5px;
}

.app-situation i {
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  line-height: 0.75;
  display: inline-block;
  padding-right: 5px;
}

.reservation {
  background-color: #fff;
  overflow: hidden;
  border-radius: 5px;
}

.reservation a {
  font-size: 12px;
  display: flex;
  padding: 10px 15px;
  position: relative;
}

.reservation-title {
  line-height: 16px;
  color: #fff;
  background-color: #ff8a00;
  width: 86px;
  height: 17px;
  padding-left: 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.reservation-title::after {
  content: "";
  background: linear-gradient(120deg, transparent 0, transparent 49%, #fff 50%, #fff 100%);
  width: 8px;
  height: 17px;
  position: absolute;
  top: 0;
  right: 0;
}

.reservation-date {
  min-width: 78px;
  padding: 15px 10px 0 0;
}

.reservation-date span {
  font-size: 19px;
  font-weight: 600;
  line-height: 16px;
  padding-right: 5px;
}

.reservation-date .reservation-no {
  font-size: 14px;
}

.shop-name span {
  color: #001e2a;
  display: block;
}

.reservation .confirmation {
  color: #ff8a00;
  padding-right: 40px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.goal {
  font-weight: 600;
  line-height: 1.4;
  color: #fff;
  padding: 18px;
  border: solid 1px #fff;
  border-radius: 5px;
  position: relative;
}

.goal-title {
  font-size: 14px;
  line-height: 1;
  background-color: #001e2a;
  padding: 0 5px;
  position: absolute;
  top: -7px;
  left: 13px;
}

/* -- slider -- */
.slide-content {
  opacity: 0;
  position: absolute;
  z-index: -1;
  transition: opacity 0.5s;
}

.slide-content.is-active {
  opacity: 1;
  position: relative;
  z-index: 1;
}

.slide-content a {
  display: flex;
}

.slide-content p {
  font-size: 12px;
  line-height: 1.5;
  color: #ff8a00;
  padding: 15px;
}

.slide-content img {
  flex-shrink: 0;
  width: 87px;
}

.slide-content img:only-child {
  width: 100%;
  height: auto;
}

.slide-nav {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.slide-nav span {
  background-color: #bbb;
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 6px;
  border-radius: 50%;
}

.slide-nav span.is-active {
  background-color: #001e2a;
}

/* -- information -- */
.info-title {
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  background-color: #001e2a;
  padding: 10px;
  padding: 15px;
  position: relative;
}

.info-title a {
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  display: block;
  width: 110px;
  height: 21px;
  border: solid 1px #fff;
  border-radius: 11px;
  position: absolute;
  top: 12px;
  right: 15px;
}

.info-list {
  background-color: #fff;
  padding: 0 15px;
}

.info-content {
  font-size: 13px;
  font-weight: 700;
  color: #001e2a;
}

.info-content:not(:last-child) {
  border-bottom: solid 1px #e6e6e6;
}

.info-content a {
  display: block;
  overflow: hidden;
  padding: 13px 25px 10px 0;
}

.info-content a::after {
  right: 5px;
}

.info-content a img {
  float: left;
  margin-right: 10px;
}

.info-content span {
  font-size: 10px;
  font-weight: 400;
  color: #808e94;
  display: block;
}

/* -- LINE -- */
.home-line_button {
  margin-top: 20px;
  padding: 0 20px;
}

.home-line_button a {
  font-size: 15px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  background-color: #00c820;
  text-align: center;
  display: block;
  height: 42px;
  padding-top: 13px;
  border-radius: 21px;
}

/* -- modal -- */
.modal-wrapper {
  background: rgba(0, 30, 42, 0.8);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  opacity: 0;
  position: fixed;
  top: 101vh;
  left: 0;
  z-index: 99;
  transition: all 0.5s ease-in;
}

.modal-content {
  font-family: serif;
  font-weight: 400;
  background: linear-gradient(
      45deg,
      rgba(169, 169, 169, 0.2) 25%,
      transparent 25%,
      transparent 75%,
      rgba(169, 169, 169, 0.2) 75%
    ),
    linear-gradient(45deg, rgba(169, 169, 169, 0.2) 25%, transparent 25%, transparent 75%, rgba(169, 169, 169, 0.2) 75%);
  background-color: #ffc700;
  background-position:
    0 0,
    38px 38px;
  background-size: 76px 76px;
  width: 100%;
  height: 80vh;
  padding: 45px 20px;
}

/* .modal-content {
  font-family: serif;
  font-weight: 400;
  background: linear-gradient(
      45deg,
      rgba(169, 169, 169, 0.2) 25%,
      transparent 25%,
      transparent 75%,
      rgba(169, 169, 169, 0.2) 75%
    ),
    linear-gradient(45deg, rgba(169, 169, 169, 0.2) 25%, transparent 25%, transparent 75%, rgba(169, 169, 169, 0.2) 75%);
  background-color: #ffc700;
  background-position:
    0 0,
    38px 38px;
  background-size: 76px 76px;
  width: 100%;
  height: 80vh;
  padding: 45px 20px;
} */

#modal-content {
  position: absolute;
  top: calc(10vh - 59px);
  left: -100vw;
  z-index: -9;
}

@font-face {
  font-family: "tamanegi_v7_2";
  font-weight: normal;
  font-style: normal;
  src: url("/fonts/tamanegi_v7_2.ttf") format("truetype");
}

.modal-paper {
  font-family: "tamanegi_v7_2", sans-serif;
  color: #001e2a;
  background-color: #fff;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  height: 0;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  position: relative;
}

#modal-content .modal-paper {
  height: 100%;
}

#modal-animation .modal-paper .goal-content,
#modal-animation .modal-paper span {
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.5s ease-in;
}

.modal-paper p,
.modal-paper .goal-text {
  writing-mode: vertical-rl;
}

.modal-paper .goal-content p span {
  writing-mode: initial;
}

.modal-paper .goal-text {
  font-size: 28px;
  display: block;
  position: absolute;
  top: 32px;
  right: 30px;
  transition-delay: 1.8s !important;
}

.modal-paper .goal-text b {
  background: url(/assets/app-home/modal_goal_icon.png) no-repeat right center / 15px auto;
  padding: 0 8px 8px 0;
}

.modal-paper .goal-content {
  font-size: 35px;
  line-height: 1.35;
  letter-spacing: 0.02em;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(80vh - 90px);
  padding: 30px 50px;
  position: relative;
  transition-delay: 2.1s !important;
}

.student-name {
  font-size: 22px;
  line-height: 1.2;
  text-align: center;
  display: block;
  width: 35px;
  position: absolute;
  bottom: 30px;
  left: 30px;
  transition-delay: 2.4s !important;
}

.student-name img {
  width: 35px;
  margin-bottom: 21px;
}

.modal-wrapper.is-open {
  opacity: 1;
  top: 0;
}

.modal-wrapper.is-open .modal-paper {
  animation: motion-x 1s linear(0, 1.05, 0.87, 1.02, 0.98, 1.01, 1, 1) 1s forwards;
}

@keyframes motion-x {
  from {
    height: 0;
  }

  to {
    height: 100%;
  }
}

.modal-wrapper.is-open #modal-animation .modal-paper .goal-content,
.modal-wrapper.is-open #modal-animation .modal-paper span {
  opacity: 1;
  transform: translateY(0);
}

.modal-button {
  display: flex;
  width: calc(100% - 40px);
  margin: 20px 20px 0;
  column-gap: 10px;
}

.modal-button button {
  font-size: 13px;
  font-weight: 700;
  color: #001e2a;
  background-color: #fff;
  width: calc(50% - 5px);
  height: 40px;
  border-radius: 5px;
}

.modal-close {
  display: block;
  width: 38px;
  height: 38px;
  margin-top: 20px;
  border: solid 1px #fff;
  border-radius: 50%;
  position: relative;
}

.modal-close::before,
.modal-close::after {
  content: "";
  background-color: #fff;
  display: block;
  width: 1px;
  height: 20px;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.modal-close::before {
  transform: rotate(45deg);
}

.modal-close::after {
  transform: rotate(-45deg);
}

/* -------- ai-dietary-advice // -------- */
.input_meal_button_box {
  margin-top: 30px;
}

.input_meal_button {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background-color: rgb(255 138 0);
  width: 100%;
  height: 42px;
  padding: 8px;
  border-radius: 5px;
}

/* -- AiDietaryAdvice.tsx -- */
.ai_dietary_advice_wrapper {
  padding-top: 55px;
  position: absolute;
  inset: 0;
}

.ai_dietary_advice {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ai_dietary_advice_selector {
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  height: 50px;
  border-bottom: solid 1px #e6e6e6;
}

.ai_dietary_advice_nutrition_summary_button {
  width: 50px;
  height: 50px;
}

.ai_dietary_advice_nutrition_summary_button button {
  font-size: 0;
  background: url(/assets/icons/icon_summary.png) no-repeat center center / 36px 36px;
  width: 50px;
  height: 50px;
}

.ai_dietary_advice_date {
  width: calc(100% - 50px);
  border: none;
  position: relative;
}

.ai_dietary_advice_date .formatted_date {
  font-size: 18px;
  font-weight: bold;
  color: #001e2a;
  padding: 12px;
}

.ai_dietary_advice_date input[type="date"] {
  font-weight: 600;
  background: none;
  width: 100%;
  height: 50px;
  border: none;
  position: relative;
}

.ai_dietary_advice_date input::-webkit-calendar-picker-indicator {
  background: transparent;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.ai_dietary_advice_date input::after {
  content: "";
  background: url(/assets/icons/icon_calendar.png) no-repeat center center / 36px 36px;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

.ai_dietary_advice_date .calendar_btn {
  background: url(/assets/icons/icon_calendar.png) no-repeat center center / 36px 36px;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

.ai_dietary_advice_date .date_btn {
  display: flex;
  position: absolute;
  top: 0;
  right: 41px;
  z-index: 1;
}

.ai_dietary_advice_date .date_btn button {
  width: 32px;
  height: 50px;
}

.ai_dietary_advice_date .date_btn .date_prev {
  background: url(/assets/icons/btn_prev.png) no-repeat center center / 11px auto;
}

.ai_dietary_advice_date .date_btn .date_next {
  background: url(/assets/icons/btn_next.png) no-repeat center center / 11px auto;
}

.ai_dietary_advice_input {
  background-color: #f3f3f3;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 65px;
  padding: 15px;
}

.ai_dietary_advice_input .summary_button {
  font-size: 0;
  background: url(/assets/icons/button_ai_input.png) no-repeat center center / 100% 100%;
  width: 50px;
  height: 35px;
}

.ai_dietary_advice_input .back_button {
  font-size: 0;
  background: url(/assets/icons/btn_next.png) no-repeat center center / 11px auto;
  width: 50px;
  height: 35px;
}

.ai_dietary_advice_input .send_button {
  font-size: 0;
  background: url(/assets/icons/btn_send.png) no-repeat center center / 60% 60%;
  width: 50px;
  height: 35px;
}

.ai_dietary_advice_input input {
  font-size: 0;
  width: calc(100% - 65px);
  height: 35px;
  border: solid 1px #d9d9d9;
  border-radius: 99px;
}

.ai_dietary_advice_meal_registration {
  width: 72px;
  height: 72px;
  position: fixed;
  right: 15px;
  bottom: 80px;
}

.ai_dietary_advice_meal_registration button {
  font-size: 0;
  background: url(/assets/icons/button_meal-registration.png) no-repeat center center / 100% 100%;
  width: 72px;
  height: 72px;
}

.ai_dietary_advice_content {
  overflow: auto;
  height: 100%;
  padding: 30px 20px 40px;
}

.ai_dietary_advice_text {
  font-size: 14px;
  line-height: 1.5;
}

.chat_no_text {
  color: #d9d9d9;
  text-align: center;
}

.chat_my_text,
.chat_ai_text {
  margin-top: 10px;
  padding: 15px;
  border-radius: 10px;
  position: relative;
}

.chat_my_text::before,
.chat_ai_text::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  top: 10px;
}

.chat_my_text {
  background-color: #f3f3f3;
  display: table;
  max-width: calc(100% - 60px);
  margin: 10px 0 0 auto;
}

.chat_ai_text {
  color: #fff;
  background-color: #001e2a;
  max-width: calc(100% - 40px);
  margin: 10px auto 0 0;
}

.chat_my_text::before {
  border-width: 0 0 15px 15px;
  border-color: transparent transparent transparent #f3f3f3;
  right: -15px;
}

.chat_ai_text::before {
  border-width: 0 15px 15px 0;
  border-color: transparent #001e2a transparent transparent;
  left: -15px;
}

.chat_btn_box {
  display: flex;
  justify-content: space-between;
  column-gap: 4px;
  position: absolute;
  bottom: 0;
  left: -54px;
}

.chat_btn_box button {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.chat_btn_box .like_button {
  background: url(/assets/icons/icon_chat_like.png) no-repeat center center / 20px 20px;
}

.chat_btn_box .like_button_on {
  background: url(/assets/icons/icon_chat_like_on.png) no-repeat center center / 20px 20px;
}

.chat_btn_box .write_button {
  background: url(/assets/icons/icon_chat_write.png) no-repeat center center / 20px 20px;
}

.chat_btn_box .right_align {
  position: relative;
  left: 22px;
}

/* -- InputMealModal.tsx -- */
.input_meal_date {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  text-align: center;
}

.input_meal_timezone {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.input_meal_timezone li {
  width: calc(25% - 5px);
}

.input_meal_timezone button {
  color: #808e94;
  background-color: #f3f3f3;
  width: 100%;
  height: 35px;
  border-radius: 3px;
}

.input_meal_timezone button.current {
  color: #fff;
  background-color: #001e2a;
}

.input_meal_title {
  font-size: 16px;
  font-weight: 600;
  color: #001e2a;
  text-align: center;
  margin-top: 30px;
}

.input_meal_textarea {
  width: 100%;
  height: 150px;
  margin-top: 10px;
  padding: 10px;
  border: solid 1px #001e2a;
  border-radius: 5px;
}

.input_meal_other,
.file_upload {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.input_meal_other button,
.file_upload .file_upload_box {
  width: calc(50% - 5px);
}

.input_meal_other button {
  font-size: 16px;
  color: #fff;
  background-color: #001e2a;
  height: 35px;
  border-radius: 3px;
}

.input_meal_other button.usual::before {
  content: "";
  background: #001e2a url(/assets/icons/icon_chat_like.png) no-repeat center center / 30px 30px;
  vertical-align: bottom;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.file_upload_box {
  background: url(/assets/icons/icon_register_image.png) no-repeat center center / 40px 40px;
  text-align: center;
  overflow: hidden;
  border: 1px dashed #d9d9d9;
  border-radius: 5px;
  aspect-ratio: 1/1;
}

.file_upload_box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.file_upload_box input[type="file"] {
  display: none;
}

.image_preview_container {
  position: relative;
  width: 100%;
  height: 100%;
}

.image_preview_container {
  position: relative;
  display: inline-block;
}
.remove_button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #000;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  cursor: pointer;
  font-weight: bold;
}

.cross_icon {
  color: #fff;
  font-size: 20px;
}

/* -- FavoriteMealModal.tsx -- */
.ai_dietary_advice_favorite_meal {
  counter-reset: number 0;
}

.ai_dietary_advice_favorite_meal_item {
  font-size: 14px;
  color: #001e2a;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 60px;
  margin-top: 8px;
  padding: 5px 30px 5px 50px;
  border: solid 1px #001e2a;
  border-radius: 3px;
  position: relative;
}

.ai_dietary_advice_favorite_meal_item::before {
  content: counter(number);
  counter-increment: number 1;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  color: #001e2a;
  text-align: center;
  display: block;
  width: 2em;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}

.ai_favorite_meal_registration {
  text-align: right;
  padding-top: 8px;
}

.favorite_register_button {
  background: url(/assets/icons/icon_register_favorite.png) no-repeat center center / 72px 72px;
  width: 72px;
  height: 72px;
}

.ai_favorite_meal_addition {
  text-align: center;
  margin-top: 20px;
}

.favorite_addition_button {
  background: url(/assets/icons/icon_register_image.png) no-repeat center center / 28px 28px;
  width: 28px;
  height: 28px;
}

.ai_dietary_advice_favorite_meal_item.current,
.ai_dietary_advice_favorite_meal_item.current::before {
  color: #fff;
  background-color: #001e2a;
}

.ai_dietary_advice_favorite_meal_item:has(button) {
  padding-right: 40px;
}

.ai_dietary_advice_favorite_meal_item button {
  width: 30px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.ai_dietary_advice_favorite_meal_item .delete {
  background: #001e2a url(/assets/icons/icon_delete.png) no-repeat center center / 18px 23px;
}

.ai_dietary_advice_favorite_meal_item .register {
  background: #ff8a00 url(/assets/icons/icon_input.png) no-repeat center center / 14px 9px;
}

.ai_dietary_advice_favorite_meal_item .favorite_register {
  width: 100%;
  border: none;
}

.ai_dietary_advice_favorite_meal_item:has(.favorite_register) {
  border-color: #ff8a00;
}

/* -------- nutrition-summary // -------- */
.summary_wrapper {
  line-height: 1;
  display: flex;
  align-content: stretch;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  position: absolute;
  inset: 0;
}

.summary_wrapper .cate1 {
  background-color: #f00;
}

.summary_wrapper .cate2 {
  background-color: #ff8a00;
}

.summary_wrapper .cate3 {
  background-color: #ffb800;
}

.summary_wrapper .cate4 {
  background-color: #00c820;
}

/* -- Selector -- */
.summary_wrapper .tab_wrapper {
  flex: 0 0 197px;
}

.summary_divide_tab {
  background-color: #f3f3f3;
  display: flex;
  height: 48px;
  margin: 20px;
  border-radius: 3px;
}

.summary_divide_tab li {
  line-height: 28px;
  display: block;
  overflow: hidden;
  flex: 1;
  height: 100%;
  padding: 10px 0;
  border-radius: 3px;
  position: relative;
  z-index: 1;
}

.summary_divide_tab li:nth-of-type(n + 2)::before {
  content: "";
  background-color: #808e94;
  width: 1px;
  height: 28px;
  position: absolute;
  top: 10px;
  left: 0;
}

.summary_divide_tab li.current {
  color: #fff;
  background-color: #001e2a;
}

.summary_divide_tab li.current::before,
.summary_divide_tab li.current + li::before {
  display: none;
}

.graph_select {
  border: none;
}

/* -- Detail -- */
.summary_graph_selector {
  color: #fff;
  background-color: #001e2a;
  display: flex;
  row-gap: 10px;
  flex: 0 0 160px;
  flex-direction: column;
  padding: 20px;
  position: relative;
}

.graph_selector_day {
  font-size: 16px;
  font-weight: 600;
  background-color: #001e2a;
  display: block;
  height: 28px;
  padding: 12px 20px 0;
  position: absolute;
  top: -26px;
  left: 0;
}

.graph_selector_day::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-right: 10px solid #fff;
  border-bottom: 27px solid #001e2a;
  position: absolute;
  top: 0;
  right: 0;
}

.summary_graph_selector > div {
  height: 55px;
}

.summary_graph_selector .line_box {
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 6px;
  border: solid 1px #fff;
  border-radius: 5px;
}

.summary_graph_selector .line_box > * {
  text-align: center;
  width: 50%;
}

.summary_graph_selector .number_box {
  font-size: 20px;
}

.summary_graph_selector .denominator {
  font-size: 15px;
  padding-left: 0.5em;
}

.summary_graph_selector .denominator b {
  padding-right: 0.25em;
}

.graph_switch {
  display: flex;
  height: 55px;
  max-height: 100%;
  column-gap: 8px;
}

.graph_switch li {
  overflow: hidden;
  flex: 1;
  border-radius: 5px;
}

.graph_switch li button {
  color: #808e94;
  background-color: #f3f3f3;
  width: 100%;
  height: 100%;
}

.line_box .graph_switch li button {
  font-size: 14px;
}

.graph_switch li button .numerator {
  font-size: 20px;
  padding: 0;
}

.graph_switch li button span {
  font-size: 16px;
  display: block;
  padding-bottom: 3px;
}

.graph_switch li button b {
  padding-right: 0.5em;
}

.graph_switch li button[class] {
  color: #fff;
}

.summary_graph_selector .line_box:last-child .lead_text,
.summary_graph_selector .line_box:last-child .number_box {
  display: block;
}

/* -- Graph -- */
.nutrition_summary {
  display: flex;
  flex: 1;
  padding-bottom: 35px;
  position: relative;
  z-index: 1;
}

.graph_scale {
  font-size: 10px;
  text-align: right;
  width: 35px;
  padding: 0 8px 40px 0;
  position: relative;
}

.graph_scale .graph_scale_unit {
  text-align: center;
  display: block;
  width: 100%;
  position: absolute;
  bottom: 15px;
}

.graph_scale_num {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.graph_scale_num li {
  flex: 1;
}

.graph_scale_num li span {
  display: block;
  position: relative;
  top: -0.5em;
}

.graph_scale_num::after {
  content: "0";
  display: block;
  position: absolute;
  right: 0;
  bottom: -0.5em;
}

.graph_layer {
  overflow-x: scroll;
  flex: 1;
  padding-bottom: 40px;
  border-top: solid 1px #e6e6e6;
  position: relative;
}

.graph_bg {
  background-image: linear-gradient(0deg, #e6e6e6 0, #e6e6e6 1px, transparent 1px, transparent 100%);
  width: calc(100% - 35px);
  height: calc(100% - 75px);
  position: absolute;
  top: 0;
  left: 0;
}

.appropriate {
  font-size: 10px;
  color: #d9d9d9;
  background: linear-gradient(0deg, #ffeded 0, #ffeded 30px, transparent 30px, transparent 100%);
  text-align: right;
  width: 100%;
  margin-top: -25px;
  padding-bottom: 30px;
  position: absolute;
  left: 0;
  z-index: -1;
}

.graph_wrap {
  display: flex;
  justify-content: flex-start;
  height: 100%;
}

.graph_box {
  display: flex;
  flex-shrink: 0;
  justify-content: space-around;
  width: 40px;
  height: 100%;
  padding: 0 4px;
  position: relative;
}

.graph_box > span {
  font-size: 10px;
  color: #808e94;
  background-color: #f3f3f3;
  text-align: center;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  bottom: -38px;
  left: 4px;
}

.graph_box span.current {
  color: #fff;
  background-color: #001e2a;
}

.graph_bar {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  height: 100%;
  margin: auto;
}

.graph_bar:only-of-type {
  width: 16px;
}

.graph_bar:first-of-type:nth-last-of-type(2),
.graph_bar:first-of-type:nth-last-of-type(2) ~ .graph_bar {
  width: 12px;
}

.graph_bar:first-of-type:nth-last-of-type(3),
.graph_bar:first-of-type:nth-last-of-type(3) ~ .graph_bar {
  width: 8px;
}

.graph_bar span {
  display: block;
  width: 100%;
}
